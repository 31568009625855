<template>
	<div>
		<p class="mb-1">Tipo de inspección</p>
		<v-radio-group
			v-model="model"
			class="mt-1"
			row
			dense
			hide-details
			:disabled="header.disabled"
		>
			<v-radio
				:label="itemRadio.label"
				:value="itemRadio.value"
				class="mb-0 mr-3"
				v-for="(itemRadio, indexR) in values"
				v-bind:key="'radio-item' + input.key + '-' + indexR"
			></v-radio>
		</v-radio-group>
	</div>
</template>
<script>
export default {
	name: 'CRadio',
	props: {
		input: Object,
	},
	computed: {
		header() {
			return this.$store.getters['inspection/getHeaderById'](this.input.id);
		},
		values: function () {
			return JSON.parse(this.input?.values || '', true) || [];
		},
		model: {
			get() {
				return this.header?.value || null;
			},
			set(value) {
				this.$store.dispatch('inspection/updateInputHeader', {
					value,
					id: this.input.id,
				});
			},
		},
	},
	dd: [
		{ label: 'Informal', value: 'informal' },
		{ label: 'Planificado', value: 'planned' },
	],
};
</script>
